<template>
  <!-- Page Content -->
  <div style="max-width: 1400px">
    <header>
      <h2 class="my-4 text-4xl font-semibold">{{title}} Sales</h2>
      <p class="text-xs">
        Manage your interactive price list and development sales
      </p>
    </header>

    <o-loading v-model="loadingPart1"></o-loading>

    <div v-if="!loadingPart1">
      <p class="pt-3">
        Total Units: <span class="font-bold">{{ units.length }}</span>
      </p>
      <p>
        Sellout Value:
        <span class="font-bold"
        >{{ totalValue | prettyCurrency }} incl. VAT</span
        ><br />
        <span class="font-bold" style="padding-left: 112px"
        >{{ totalValueExclVat | prettyCurrency }} excl. VAT</span
        >
      </p>

      <div class="flex flex-row flex-wrap pt-5">
        <div class="flex flex-col w-full pr-2 mb-5">
          <div
            class="card rounded-md p-4 relative shadow-lg"
            style="border-left: 5px solid #aa9462; background: #1e293b"
          >
            <p class="text-white uppercase font-bold">Total Sales</p>
            <span class="text-white absolute right-4 top-4 text-md"
            >{{ filteredUnits.length }}
              <sup class="font-semibold" style="color: #32cd32"
              >{{
                  ((filteredUnits.length / units.length) * 100).toFixed(0)
                }}%</sup
              ></span
            >

            <div class="text-sm text-primary font-semibold">
              <label>
                <input
                  type="checkbox"
                  v-model="filters"
                  class="mx-2 rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                  value="pending"
                />
                Pending</label
              >
              <label>
                <input
                  type="checkbox"
                  v-model="filters"
                  class="mx-2 rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                  value="pendingUnitDetails"
                />
                Pending Details</label
              >
                <label>
                  <input
                    type="checkbox"
                    v-model="filters"
                    class="mx-2 rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                    value="pendingPayment"
                  />
                  Pending Payment</label
                >
              <label>
                <input
                  type="checkbox"
                  v-model="filters"
                  class="mx-2 rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                  value="reserved"
                />
                Reserved
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="filters"
                  class="mx-2 rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                  value="sold"
                />
                Sold
              </label>
            </div>
            <p class="text-white font-bold pt-3">
              {{ filteredSales.toFixed(0) | prettyCurrency }}
            </p>
            <p class="text-white text-sm">
              {{ filteredSalesExcVat | prettyCurrency }} ex vat
            </p>
            <span
              class="text-3xl text-primary absolute bottom-4 right-4 font-bold"
            >{{ ((filteredSales / totalValue) * 100).toFixed(0) }}%</span
            >
          </div>
        </div>
        <DashboardCard
          title="Available"
          description="Total Units Remaining"
          :total="availableTotal"
          :sales="availableSales"
          :percentage="availablePercentage"
          :total-sales="totalSales"
          :total-units="units.length"
        />
        <DashboardCard
          title="Pending"
          description="Booked but not confirmed"
          :total="pendingTotal"
          :sales="pendingSales"
          :percentage="pendingPercentage"
          :total-sales="totalSales"
          :total-units="units.length"
        />
        <DashboardCard
          title="Reserved"
          description="Confirmed &amp; reservation deposit paid"
          :total="reservedTotal"
          :sales="reservedSales"
          :percentage="reservedPercentage"
          :total-sales="totalSales"
          :total-units="units.length"
        />
        <DashboardCard
          title="Sold"
          description="Contract Received"
          :total="soldTotal"
          :sales="soldSales"
          :percentage="soldPercentage"
          :total-sales="totalSales"
          :total-units="units.length"
        />
      </div>
    </div>

    <o-loading v-model="loadingPart2"></o-loading>
    <!-- Charts -->
    <!--    ____ Commited out as it breaking the page ____-->
    <!--    <div v-if="!loadingPart2">-->
    <div>
      <h2 class="my-4 text-4xl font-semibold">Google Analytics</h2>
      <div class="mt-5">
        <div class="grid grid-cols-1 md:grid-cols-12">
          <div class="col-span-12 md:col-span-3">
            <div
              class="card rounded-md p-4 bg-white relative shadow-lg mr-3"
              style="border-left: 5px solid #03be0b"
            >
              <span class="font-bold text-md uppercase">Realtime Users</span>
              <span class="block text-xs font-semibold" style="color: #03be0b"
              >Active users on the site</span
              >
              <div class="text-3xl font-bold text-right" style="color: #03be0b">
                {{ realtimeTotal }}
              </div>
            </div>

            <div
              class="card rounded-md p-4 bg-white relative shadow-lg mt-3 mr-3"
              style="border-left: 5px solid #5b99f0"
            >
              <span class="font-bold text-md uppercase">All users</span>
              <span class="block text-xs font-semibold" style="color: #5b99f0"
              >All the users over the last 14 days</span
              >
              <div class="text-3xl font-bold text-right" style="color: #5b99f0">
                {{ allUsers }}
              </div>
            </div>
            <div
              class="card rounded-md p-4 bg-white relative shadow-lg mt-3 mr-3"
              style="border-left: 5px solid #5b99f0"
            >
              <span class="font-bold text-md uppercase">New users</span>
              <span class="block text-xs font-semibold" style="color: #5b99f0"
              >Over the last 14 days</span
              >
              <div class="text-3xl font-bold text-right" style="color: #5b99f0">
                {{ newUsers }}
              </div>
            </div>
            <div
              class="card rounded-md p-4 bg-white relative shadow-lg mt-3 mr-3"
              style="border-left: 5px solid #5b99f0"
            >
              <span class="font-bold text-md uppercase"
              >Avg. Session Duration</span
              >
              <span class="block text-xs font-semibold" style="color: #5b99f0"
              >Over the last 14 days</span
              >
              <div class="text-3xl font-bold text-right" style="color: #5b99f0">
                {{ formattedAvgSessionDuration }}
              </div>
            </div>
          </div>
          <div class="col-span-12 md:col-span-9 mt-3 md:mt-0 pr-2 md:px-2">
            <div
              class="card rounded-md p-4 bg-white relative shadow-lg"
              style="border-left: 5px solid #5b99f0; padding-bottom: 50px"
            >
              <p class="uppercase font-bold" style="color: #1e293b">
                Pageviews
              </p>
              <p class="text-xs mb-5 font-semibold" style="color: #5b99f0">
                Pageviews over the last 14 days (Data excludes current day)
              </p>
              <p
                class="float-right uppercase font-bold text-3xl"
                style="color: #5b99f0"
              >
                {{ totalPageViews }}
              </p>
              <line-chart
                style="max-height: 380px"
                v-if="loaded"
                :chartdata="chartdata"
                :options="chartoptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {unitsCollection} from "@/firebase";
import toInteger from "lodash/toInteger";
import { sumBy, sum } from "lodash";
import {
  // GA_REALTIME_URL,
  GA_PAGEVIEWS_URL,
  GA_ALL_USERS_URL,
  GA_NEW_USERS_URL,
  GA_AVG_SESSION_DURATION_URL, APP_TITLE,
} from "@/Constants";
import DashboardCard from "../../components/admin/DashboardCard";
import LineChart from "../../components/admin/LineChart";
import { addDays, format } from "date-fns";
import {mapGetters} from 'vuex'

export default {
  name: "AdminDashboard",
  components: {
    DashboardCard,
    LineChart,
  },
  data: () => ({
    title: APP_TITLE,
    dashboardData: null,
    units: [],
    filters: ['pending', 'pendingUnitDetails', 'pendingPayment', "reserved", "sold"],
    // realtimeTotal: 0,
    chartdata: [],
    loaded: false,
    loadingPart1: true,
    loadingPart2: true,
    chartoptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
    totalPageViews: 0,
    allUsers: 0,
    newUsers: 0,
    intervalHdl: null,
    avgSessionDuration: 0,
    formattedAvgSessionDuration: "",
  }),
  methods: {
    // async realtime() {
    //   let result = await axios.get(GA_REALTIME_URL);
    //   if (result.data && result.data.length > 0) {
    //     this.realtimeTotal = sum(
    //       result.data.map((item) => parseInt(item.metricValues[0].value, 10))
    //     );
    //     return;
    //   }
    //   this.realtimeTotal = 0;
    // },
    async fetchAllUsers() {
      let result = await axios.get(GA_ALL_USERS_URL);
      if (result.data && result.data.length > 0) {
        this.allUsers = sum(
          result.data.map((item) => parseInt(item.metricValues[0].value, 10))
        );
        return;
      }
      this.allUsers = 0;
    },
    async fetchNewUsers() {
      let result = await axios.get(GA_NEW_USERS_URL);
      if (result.data && result.data.length > 0) {
        this.newUsers = sum(
          result.data.map((item) => parseInt(item.metricValues[0].value, 10))
        );
        return;
      }
      this.newUsers = 0;
    },
    async fetchAvgSessionDuration() {
      let response = await axios.get(GA_AVG_SESSION_DURATION_URL);
      if (response.data.result) {
        this.avgSessionDuration = response.data.result;
        this.formattedAvgSessionDuration = new Date(
          this.avgSessionDuration * 1000
        )
          .toISOString()
          .substr(11, 8);
        return;
      }
      this.avgSessionDuration = 0;
    },
    async pageviews() {
      let result = await axios.get(GA_PAGEVIEWS_URL);
      let startDate = addDays(new Date(), -14);
      let data = result.data.map((item) => ({
        date: item.dimensionValues[0].value,
        value: parseInt(item.metricValues[0].value, 10),
      }));
      let chartData = [];
      while (startDate < Date.now()) {
        const found = data.find(
          (item) => item.date === format(startDate, "yyyyMMdd")
        );
        if (found) {
          chartData.push({
            date: format(startDate, "yyyy-MM-dd"),
            value: found.value,
          });
          this.totalPageViews += found.value;
        } else {
          chartData.push({ date: format(startDate, "yyyy-MM-dd"), value: 0 });
        }
        startDate = addDays(startDate, 1);
      }
      this.chartdata = {
        labels: chartData.map((item) => item.date),
        datasets: [
          {
            label: "Pageviews",
            backgroundColor: "#e0edfe",
            borderColor: "#5b99f0",
            fill: true,
            data: chartData.map((item) => item.value),
          },
        ],
      };
      this.loaded = true;
      this.loadingPart2 = false;
    },
    getPercent(part, total) {
      const percent = `${Math.round((part / total) * 100)}%`;
      return percent;
    },
  },
  computed: {
    ...mapGetters({
      realtimeTotal: 'realtimeTotal'
    }),
    //...mapGetters('state', ['realtimeTotal']),
    filteredUnits() {
      return this.units.filter((item) => this.filters.includes(item.status));
    },
    filteredSales() {
      return sumBy(this.filteredUnits, "price");
    },
    filteredSalesExcVat() {
      return (this.filteredSales * (100 / 115)).toFixed(0);
    },
    totalValue() {
      return sumBy(this.units, "price");
    },
    totalValueExclVat() {
      return (this.totalValue * (100 / 115)).toFixed(0);
    },
    totalSales() {
      return sumBy(
        this.units.filter((item) =>
          ["pending", "reserved", "sold"].includes(item.status)
        ),
        "price"
      );
    },
    totalSalesExclVat() {
      return (this.totalSales * (100 / 115)).toFixed(0);
    },
    numberOfUnitsSoldTitle() {
      const totalReservedUnits =
        this.dashboardData.totalReservedUnits +
        this.dashboardData.totalSoldUnits;
      const totalUnits = this.dashboardData.totalUnits;

      return `${totalReservedUnits}/${totalUnits}`;
    },
    availableUnits() {
      return this.units.filter((item) => item.status === "available");
    },
    availableTotal() {
      return this.availableUnits.length;
    },
    availableSales() {
      if (this.availableTotal === 0) {
        return 0;
      }
      return sumBy(this.availableUnits, "price");
    },
    availablePercentage() {
      return (this.availableSales / this.totalValue) * 100;
    },
    pendingUnits() {
      return this.units.filter((item) => item.status === 'pending');
    },
    pendingUnitDetailsUnits() {
      return this.units.filter((item) => item.status === 'pendingUnitDetails');
    },
    pendingPaymentUnits() {
      return this.units.filter((item) => item.status === 'pendingPayment');
    },
    pendingTotal() {
      return this.pendingUnits.length + this.pendingUnitDetailsUnits.length + this.pendingPaymentUnits.length;
    },
    pendingSales() {
      if (this.pendingTotal === 0) {
        return 0;
      }
      return sumBy(this.pendingUnits, "price") + sumBy(this.pendingUnitDetailsUnits, "price") + sumBy(this.pendingPaymentUnits, "price");
    },
    pendingPercentage() {
      return (this.pendingSales / this.totalValue) * 100;
    },
    reservedUnits() {
      return this.units.filter((item) => item.status === "reserved");
    },
    reservedTotal() {
      return this.reservedUnits.length;
    },
    reservedSales() {
      if (this.reservedTotal === 0) {
        return 0;
      }
      return sumBy(this.reservedUnits, "price");
    },
    reservedPercentage() {
      return (this.reservedSales / this.totalValue) * 100;
    },
    soldUnits() {
      return this.units.filter((item) => item.status === "sold");
    },
    soldTotal() {
      return this.soldUnits.length;
    },
    soldSales() {
      if (this.soldTotal === 0) {
        return 0;
      }
      return sumBy(this.soldUnits, "price");
    },
    soldPercentage() {
      return (this.soldSales / this.totalValue) * 100;
    },
  },
  mounted() {
    unitsCollection.onSnapshot((snap) => {
      this.units = [];
      snap.forEach((doc) => {
        let price;

        if (doc.data().setGlobalDiscountPrice && doc.data().discountType === "global" && doc.data().purchasePrice) {
          price = toInteger(doc.data().purchasePrice);
        } else {
          price = toInteger(doc.data().price)
        }
        this.units.push({ status: doc.data().status, price: price });
      });
      this.loadingPart1 = false;
    });
    this.pageviews();
    this.fetchNewUsers();
    this.fetchAllUsers();
    this.fetchAvgSessionDuration();
  },
  beforeDestroy() {
    if (this.intervalHdl) {
      clearInterval(this.intervalHdl);
    }
  },
};
</script>

<style>
.chart-card {
  background: #fff;
}
</style>
