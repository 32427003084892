<template>
  <div class="flex flex-col pr-2 mb-5" :class="width">
    <div
      class="card rounded-md p-4 bg-white relative shadow-lg"
      style="border-left: 5px solid #aa9462"
    >
      <p class="uppercase font-bold" style="color: #1e293b">{{ title }}</p>
      <p class="text-primary font-semibold text-xs">{{ description }}</p>
      <span class="absolute right-4 top-4 text-md"
        >{{ total }}
        <sup class="font-semibold" style="color: #32cd32"
          >{{ ((total / totalUnits) * 100).toFixed(0) }}%</sup
        ></span
      >
      <p class="uppercase font-bold pt-3" style="color: #1e293b">
        {{ sales.toFixed(0) | prettyCurrency }}
      </p>
      <p class="text-xs">{{ salesEx | prettyCurrency }} ex vat</p>
      <span class="text-3xl text-primary absolute bottom-4 right-4 font-bold"
        >{{ percentage.toFixed(0) }}%</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
    sales: {
      type: Number,
      default: 0,
    },
    totalUnits: {
      type: Number,
      default: 0,
    },
    totalSales: {
      type: Number,
      default: 0,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "w-full lg:w-1/2",
    },
  },
  computed: {
    salesEx() {
      return (this.sales * (100 / 115)).toFixed(0);
    },
  },
};
</script>